<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>A JITHENDRA SAI HRUSHIKESH</h2>
                    <h3>Software Developer</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description"  | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <button (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")' ngbNavLink (click)='scroll("about")' target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </button>
            </div>
        </div>
    </div>
</section>

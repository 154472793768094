<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up"
                            data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">Angular 8+ </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">TypeScript </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">JavaScript </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">Ngrx </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">SSO </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">Python - Django stack </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">ASP.NET Web API </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">JIIRA </span></li>

                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">SQL </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">HTML5 &amp; (S)CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">Machine learning </span></li>



                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">NLP </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">GIT </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span
                                    class="underline">Jasmine & karma
                                </span></li>


                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'
                            class="rounded" height="300" src="assets/images/me.jpeg" alt="jithendra">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>